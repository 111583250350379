import axios from "axios";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // timeout: Config.timeout, // 请求超时时间
});
// request拦截器
service.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // Do something with request error

    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data)
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default service;
