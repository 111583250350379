<template>
  <div id="Channel">
    <div class="list">
      <div class="back" v-show="activeData.title" @click="backFun">
        <span class="icon-leftIcon"></span>
        <span>{{ activeData.title }}</span>
      </div>
      <ul>
        <li
          :class="{ activeXW: activeCode && activeCode == item.code }"
          v-for="(item, index) in listData"
          :key="index"
          @click="clickFun(item)"
        >
          {{ item.title }}
          <span v-show="!channelCode.includes(item.code)">{{
            item.code.indexOf("EX") != -1 ? "" : item.code
          }}</span>
        </li>
      </ul>
    </div>
    <div class="detail"></div>
  </div>
</template>

<script>
import { getAcupointCategory, getAcupointTarget } from "@/api/index";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Channel",
  data() {
    return {
      listData: [],
      oldListData: [],
      activeData: {},
      activeCode: null,
      channelCode: [
        "RD-DM",
        "RD-RM",
        "STYFJ",
        "QX",
        "SQYXBJ",
        "SSYSJJ",
        "SSYXJ",
        "STYXCJ",
        "SYMDCJ",
        "ZQYGJ",
        "ZSYDJ",
        "ZSYSJ",
        "ZTYPGJ",
        "ZTYPJ",
        "ZYMWJ",
      ],
    };
  },
  // props: ["clickState"],
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    //选择穴位
    clickFun(data) {
      // if (!this.clickState) return;
      if (data.subAcupointCategory && data.subAcupointCategory.length) {
        this.activeData = data;
        this.listData = data.subAcupointCategory.filter((item) => {
          return ![
            "EX_HN9_L",
            "EX_HN10",
            "EX_HN11",
            "EX_HN12",
            "EX_HN13",
            "RN1",
            "DU28",
          ].includes(item.code); //删除未标注穴位
        });
      }
      if (this.activeCode && this.activeCode == data.code) return;
      this.activeCode = data.code;
      this.$emit("activeCode", data);
    },
    //重置activeCode、activeData和listData属性为初始值，并为activeCode事件生成一个null值。
    backFun() {
      this.activeCode = null;
      this.activeData = {};
      this.listData = this.oldListData;
      this.$emit("activeCode", null);
    },
    getData() {
      getAcupointCategory()
        .then((res) => {
          if (res.code == 200) {
            this.listData = res.data;
            this.oldListData = JSON.parse(JSON.stringify(res.data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getXueWei() {
      getAcupointTarget()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#Channel {
  position: absolute;
  right: 0;
  top: 4%;
  z-index: 5;
  // width: 90px;
  overflow: hidden;
  -webkit-transition: right 0.3s;
  -o-transition: right 0.3s;
  transition: right 0.3s;
  .list {
    .back {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: rgb(245, 76, 76);
      span {
        font-size: 16px;
        letter-spacing: 4px;
      }
    }
    ul {
      height: 52vh;
      overflow-y: auto;
      li {
        margin: 10px 0;
        font-size: 16px;
        cursor: pointer;
        margin-right: 10px;
        span {
          color: #cacaca;
          font-size: 14px;
          letter-spacing: 0px;
        }
      }
      .activeXW {
        font-weight: 600;
        color: rgb(245, 76, 76);
        span {
          font-weight: 500;
          color: rgb(245, 76, 76);
        }
      }
    }
  }
}
@keyframes right {
  0% {
    right: 0;
  }
  100% {
    width: -90px;
  }
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
