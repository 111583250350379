export const modelFile = {
  "human":"/static/3DModel/人体模型.glb",
  "RD-DM":"/static/3DModel/督脉.glb",
  "RD-RM":"/static/3DModel/任脉.glb",
  "STYFJ":"/static/3DModel/肺经.glb",
  "QX":"/static/3DModel/经外奇穴.glb",
  "SQYXBJ":"/static/3DModel/手厥阴心包经.glb",
  "SSYSJJ":"/static/3DModel/手少阳三焦经.glb",
  "SSYXJ":"/static/3DModel/手少阴心经.glb",
  "STYXCJ":"/static/3DModel/手太阳小肠经.glb",
  "SYMDCJ":"/static/3DModel/手阳明大肠经.glb",
  "ZQYGJ":"/static/3DModel/足厥阴肝经.glb",
  "ZSYDJ":"/static/3DModel/足少阳胆经.glb",
  "ZSYSJ":"/static/3DModel/足少阴肾经.glb",
  "ZTYPGJ":"/static/3DModel/足太阳膀胱经.glb",
  "ZTYPJ":"/static/3DModel/足太阴脾经.glb",
  "ZYMWJ":"/static/3DModel/足阳明胃经.glb",
}

export const fontFile = "/module/fonts/SimHei_Regular.json"