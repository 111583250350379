import request from '@/utils/request'

export function getAcupointCategory() {
  return request({
    url: 'studytool/studyTool/acupoint/no-auth/getAcupointCategory',
    method: 'GET',
  })
}

export function getAcupointTarget(data) {
  return request({
    url: 'studytool/studyTool/acupoint/no-auth/getAcupointTarget',
    method: 'GET',
    params: data
  })
}