import * as THREE from "three";
/**
 * 利用indexedDB写入json数据
 * @param {string} dbName 数据库名称
 * @param {string} storeName 存储名称
 * @param {string} key 存储键
 * @param {object} data 存储数据
 * @param {function} onSuccess 写入成功回调函数
 * @param {function} onError 写入失败回调函数
 */
export function writeJsonToIndexedDB(
  dbName,
  storeName,
  key,
  data,
  onSuccess,
  onError
) {
  const request = window.indexedDB.open(dbName);
  request.onerror = function (event) {
    onError(event.target.errorCode);
  };
  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction([storeName], "readwrite");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.put(data, key);
    request.onsuccess = function (event) {
      onSuccess(event.target.result);
    };
    request.onerror = function (event) {
      onError(event.target.errorCode);
    };
  };
}

/**
 * 利用indexedDB读取json数据
 * @param {string} dbName 数据库名称
 * @param {string} storeName 存储名称
 * @param {string} key 存储键
 * @param {function} onSuccess 读取成功回调函数
 * @param {function} onError 读取失败回调函数
 */
export function readJsonFromIndexedDB(
  dbName,
  storeName,
  key,
  onSuccess,
  onError
) {
  const request = window.indexedDB.open(dbName);
  request.onerror = function (event) {
    onError(event.target.errorCode);
  };
  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction([storeName], "readonly");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(key);
    request.onsuccess = function (event) {
      onSuccess(event.target.result);
    };
    request.onerror = function (event) {
      onError(event.target.errorCode);
    };
  };
}
/**
 * 利用indexedDB一次性读取所有json数据
 * @param {string} dbName 数据库名称
 * @param {string} storeName 存储名称
 * @param {function} onSuccess 读取成功回调函数
 * @param {function} onError 读取失败回调函数
 */
export function readAllJsonFromIndexedDB(
  dbName,
  storeName,
  onSuccess,
  onError
) {
  const request = window.indexedDB.open(dbName);
  request.onerror = function (event) {
    onError(event.target.errorCode);
  };
  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction([storeName], "readonly");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();
    request.onsuccess = function (event) {
      onSuccess(event.target.result);
    };
    request.onerror = function (event) {
      onError(event.target.errorCode);
    };
  };
}
/**
 * 判断indexedBD数据库是否存在
 * @param {string} dbName 数据库名称
 * @param {function} onSuccess 判断成功回调函数
 * @param {function} onError 判断失败回调函数
 */
export function checkIndexedDBExistence(dbName, onSuccess, onError) {
  const request = window.indexedDB.open(dbName);
  request.onerror = function (event) {
    onError(event.target.errorCode);
  };
  request.onsuccess = function (event) {
    onSuccess(true, event.target);
  };
}
/**
 * 动态创建indexedDB数据库
 * @param {string} dbName 数据库名称
 * @param {string} storeName 存储名称
 * @param {string} keyPath 键路径
 * @param {function} onSuccess 创建成功回调函数
 * @param {function} onError 创建失败回调函数
 */
export function createIndexedDB(
  dbName,
  storeName,
  keyPath,
  onSuccess,
  onError
) {
  const request = window.indexedDB.open(dbName);
  request.onerror = function (event) {
    onError(event.target.errorCode);
  };
  request.onupgradeneeded = function (event) {
    const db = event.target.result;
    const objectStore = db.createObjectStore(storeName, { keyPath: keyPath });
    onSuccess(objectStore);
  };
  request.onsuccess = function (event) {
    event.target.result.close();
  };
}
/**
 * 生成对比色
 * @param {string} color 颜色值，如"#FFFFFF"
 * @returns {string} 对比色值，如"#000000"
 */
export function generateContrastColor(color) {
  const yiq = (color.r * 299 + color.g * 587 + color.b * 114) / 1000;
  return yiq >= 128 ? "0x000000" : "#e5e6eb";
}
/**
 * 显示模型
 * @param {THREE.Object3D} object 模型对象
 */
export function showModel(object) {
  object.visible = true;
}

/**
 * 隐藏模型
 * @param {THREE.Object3D} object 模型对象
 */
export function hideModel(object) {
  object.visible = false;
}

/**
 * 将相机视角定位到模型位置
 * @param {THREE.Camera} camera 相机对象
 * @param {THREE.Object3D} object 模型对象
 * @param {number} distance 相机距离模型的距离
 */
// const pointArrTail = [
//   "SI1",
//   "SI2",
//   "SI3",
//   "SI4",
//   "SI5",
//   "SI6",
//   "SI7",
//   "ST38",
//   "ST39",
//   "ST40",
//   "SP5",
//   "SP6",
//   "SP7",
//   "SP8",
//   "HT4",
//   "HT5",
//   "HT6",
//   "HT7",
//   "HT8",
//   "HT9",
//   "BL65",
//   "BL66",
//   "BL67",
//   "SJ1",
//   "SJ2",
//   "SJ3",
//   "SJ4",
//   "SJ5",
//   "SJ6",
//   "SJ7",
//   "SJ8",
//   "SJ9",
//   "GB34",
//   "GB35",
//   "LR5",
//   "LR6",
//   "EX_HN6_L",
//   "EX_UE3_L",
//   "EX_UE6_L",
//   "EX_UE7_L",
//   "EX_LE6_L",
// ];
// const pointArrHead = [
//   "LI17",
//   "LI11",
//   "LI12",
//   "LI13",
//   "LI14",
//   "LI15",
//   "LI18",
//   "KI8",
//   "SJ21",
//   "SJ22",
//   "EX_LE8_L",
// ];
//取绝对值，左旋转
const leftAbs = ['LI11','LI12','LI13','LI14','LI15','LI16','LI17','LI18','ST5','ST6','ST7','ST35','ST36','ST37','ST38','ST39','ST40','ST41','ST42','SP21','EX_LE7_L','EX_UE3_L','EX_UE4_L','EX_UE7_L','EX_UE8_L','EX_LE6_L'];
//取绝对值，右旋转
const rightAbs = ['SP5','SP6','SP7','KI2','KI3','KI4','KI5','KI6','KI7','KI8','LR5','LR6','EX_LE8_L'];
//取绝对值
const absArr = ['EX_LE7_L'];
//取负值，上旋转
const lostTopArr = ['HT4','HT5','HT6','HT7','HT8','HT9']
//取负值，右旋转
const lostRight = ['BL61','BL62','BL63','BL64','BL65','BL66','BL67']
//取负值
const lostArr = ['SI1','SI2','SI3','SI4','SI5','SI6','SI7','SJ1','SJ2','SJ3','SJ4','SJ5','SJ6','SJ7','SJ8','SJ9','EX_UE6_L']
//右旋转
const rightArr = ['SI16','SI17','SI18','SI19','SJ16','SJ17','SJ18','SJ19','SJ20','SJ21','SJ22','GB1','GB2','GB3','GB4','GB5','GB6','GB7','GB8','GB9','GB10','GB11','GB12','GB13','GB14','GB15','GB16','GB17','GB18','GB31','GB32','GB33','GB34','GB35','GB36','GB37','GB38','GB39','GB40','GB41','EX_HN6_L','EX_HN5_L','EX_HNQianzheng_L','EX_HN14_L']

const specialPoint_top = ["BL6", "BL7", "BL8", "DU20", "DU21", "EX_HN1"];
const specialPoint_bottom = ["KI1", "EX_LE11_L"];
export function focusOnModel(camera, object, distance) {
  const box = new THREE.Box3().setFromObject(object);
  const center = box.getCenter(new THREE.Vector3());
  const size = box.getSize(new THREE.Vector3());
  const maxDim = Math.max(size.x, size.y, size.z);
  const fov = camera.fov * (Math.PI / 180);
  let cameraZ = center.z < 0 ? (maxDim / 4) * Math.tan(fov * 2) : Math.abs((maxDim / 4) * Math.tan(fov * 2));
  cameraZ *= distance;
  if (leftAbs.includes(object.userData.id)) {
    cameraZ = Math.abs(cameraZ);
    center.x += 2;
  }
  if (rightAbs.includes(object.userData.id)) {
    cameraZ = Math.abs(cameraZ);
    center.x -= 2;
  }
  if (absArr.includes(object.userData.id)) {
    cameraZ = Math.abs(cameraZ);
  }
  if (lostTopArr.includes(object.userData.id)) {
    cameraZ *= -1;
    center.y -= 2;
  }
  if (lostRight.includes(object.userData.id)) {
    // cameraZ *= -1;
    cameraZ = Math.abs(cameraZ);
    center.x += 3;
  }
  if (lostArr.includes(object.userData.id)) {
    cameraZ = Math.abs(cameraZ)*-1;
  }
  if (rightArr.includes(object.userData.id)) {
    center.x += 2;
  }
  if (specialPoint_top.includes(object.userData.id)) {
    center.y += 2;
  }
  if (specialPoint_bottom.includes(object.userData.id)) {
    center.y -= 2;
  }
  let position = { x: center.x, y: center.y, z: cameraZ };
  return position;
}

/**
 * 传入threejs鼠标选中对象，查找自定义属性中id相同的对象同时修改颜色
 * @param {THREE.Object3D} object 鼠标选中的对象
 * @param {Array} objects 包含自定义属性id的对象数组
 * @param {THREE.Color} color 需要修改的颜色
 */
export function findObjectByIdAndChangeColor(object, objects, color) {
  for (let i = 0; i < objects.length; i++) {
    if (objects[i].material.userData.id === object.material.userData.id) {
      objects[i].material.emissive = color;
    }
  }
}

/**
 * 设置threejs渲染器参数以提高帧率
 * @param {THREE.WebGLRenderer} renderer 渲染器对象
 */
export function setRendererParameters(renderer) {
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.gammaFactor = 2.2;
  renderer.gammaOutput = true;
  renderer.physicallyCorrectLights = true;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.0;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
}
/**
 * 递归查找包含自定义属性code的对象
 * @param {THREE.Object3D[]} objects 3D对象数组
 * @param {string} code 自定义属性code
 * @returns {THREE.Object3D} 包含自定义属性code的对象
 */
export function findObjectByCode(objects, code) {
  for (let i = 0; i < objects.length; i++) {
    const object = objects[i].model ? objects[i].model : objects[i];
    if (
      object.userData.id === code ||
      (object.material && object.material.userData.id === code)
    ) {
      return object;
    }
    if (object.children.length > 0) {
      const childObject = findObjectByCode(object.children, code);
      if (childObject !== undefined) {
        return childObject;
      }
    }
  }
}

/**
 * 对象克隆
 * @param {THREE.Object3D} object 需要克隆的对象
 * @returns {THREE.Object3D} 克隆后的对象
 */
export function cloneObject(object) {
  const clone = object.clone();
  clone.traverse((child) => {
    if (child.isMesh) {
      child.material = child.material.clone();
    }
  });
  return clone;
}
/**
 * 获取对象的旋转轴
 * @param {THREE.Object3D} object 对象
 * @returns {THREE.Vector3} 旋转轴
 */
export function getObjectRotationAxis(object) {
  const quaternion = object.quaternion.clone();
  const axis = new THREE.Vector3(1, 0, 0);
  axis.applyQuaternion(quaternion);
  return axis;
}

/**
 * 获取组或模型的中心坐标
 * @param {THREE.Group|THREE.Mesh} object 组或模型对象
 * @returns {THREE.Vector3} 中心坐标
 */
export function getObjectCenter(object) {
  const box = new THREE.Box3().setFromObject(object);
  return box.getCenter(new THREE.Vector3());
}

/**
 * 使相机正对组或mesh
 * @param {THREE.Camera} camera 相机对象
 * @param {THREE.Group|THREE.Mesh} object 组或模型对象
 * @param {number} distance 相机距离模型的距离
 */
export function focusOnObject(camera, object, distance) {
  const center = getObjectCenter(object);
  camera.position.set(center.x, center.y, center.z + distance);
  camera.lookAt(center);
}
/**
 * 相机通过传入的quaternion进行旋转
 * @param {THREE.Camera} camera 相机对象
 * @param {THREE.Quaternion} quaternion 旋转四元数
 */
export function rotateCameraByQuaternion(camera, quaternion) {
  camera.quaternion.multiplyQuaternions(quaternion, camera.quaternion);
}

export function operationQuaternion(quaternion) {
  const newQuaternion = new THREE.Quaternion();
  newQuaternion.clone(quaternion);
}
// 通过quaternion求欧拉角
export function calculateEuler(quaternion) {
  return new THREE.Euler().setFromQuaternion(quaternion);
}

// 通过控制器获取的当前的水平旋转弧度，使组或对象旋转
export function rotateObjectByHorizontalAngle(object, angle) {
  const axis = new THREE.Vector3(0, 1, 0);
  const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, angle);
  object.quaternion.multiplyQuaternions(quaternion, object.quaternion);
}

// 通过position调整相机位置
export function adjustCameraPosition(camera, position) {
  camera.position.set(position.x, position.y, position.z);
}
