import * as THREE from 'three';
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
const fontLoader = new FontLoader();
let fontMeshArr = [];

// const textOption = {
//   // font: null,
//   // size: 0.08,
//   size: 2,
//   height: 0.01,
//   curveSegments: 1,
//   bevelEnabled: false,
//   bevelThickness: 0.02,
//   bevelSize: 0,
//   bevelSegments: 1,
// }

export function fontInit(url, load, onProgress, onError) {
  fontLoader.load(url, load, onProgress, onError)
}

export function createFontMesh(text, option = {}) {
  let fontGeometry = new TextGeometry(text, option)
  fontGeometry.translate(0, 0, 10)
  let fontMaterial = new THREE.MeshBasicMaterial({ color: option.color });
  let fontMesh = new THREE.Mesh(fontGeometry, fontMaterial);
  fontMesh.position.set(option.position.x, option.position.y, option.position.z)
  fontMeshArr.push(fontMesh)
  return fontMesh
}

export function fontRotate(rotateData) {
  for (let i = 0; i < fontMeshArr.length; i++) {
    fontMeshArr[i].lookAt(rotateData)
  }
}