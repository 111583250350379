<template>
  <div id="app">
    <Views></Views>
  </div>
</template>

<script>
import Views from "./views/home.vue";

export default {
  name: "App",
  components: {
    Views,
  },
};
</script>

<style>
#app {
}
</style>
