import Vue from 'vue';
import App from './App.vue';
import * as THREE from 'three';
import VueWorker from 'vue-worker';
import FastClick from 'fastclick';

Vue.use(VueWorker);
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

Vue.config.productionTip = false;
Vue.prototype.$Three = THREE;
// FastClick(document.body);
FastClick.attach(document.body);
new Vue({
  render: (h) => h(App),
}).$mount('#app');
