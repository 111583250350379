<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="Manikin3D">
    <canvas id="canvasMain"></canvas>
    <div
      class="propBox"
      :class="{ propShow: acAcupoint, propHide: !acAcupoint }"
      v-show="propShow"
    >
      <div class="tit" @click="acAcupoint = !acAcupoint">{{ prop.name }}</div>
      <div class="main">
        <p v-for="(item, index) in prop.content" :key="index">
          <span>{{ item.targetName }}：</span>{{ item.content }}
        </p>
      </div>
    </div>
    <!-- <radial-progress-bar
      v-if="completedSteps != totalSteps"
      :diameter="200"
      :completed-steps="completedSteps"
      :total-steps="totalSteps"
    >
      <p>总资源: {{ totalSteps }}</p>
      <p>已加载资源: {{ completedSteps }}</p>
    </radial-progress-bar> -->
    <channel-vue @activeCode="getCode"></channel-vue>
  </div>
</template>

<script>
import bodyImgPng from "@/assets/HG_Israel_body_Base Color_base color.png";
import eyesImgPng from "@/assets/HG_Dale_eyes_Base Color_base color.png";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
// import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import TWEEN from "@tweenjs/tween.js";
// eslint-disable-next-line no-unused-vars
import { createFontMesh, fontInit, fontRotate } from "../assets/js/font";
// import Stats from "three/examples/jsm/libs/stats.module";
import { LoadingManager, BufferGeometry } from "three";
// import RadialProgressBar from "vue-radial-progress";
import { getAcupointTarget } from "@/api/index";
import {
  generateContrastColor,
  findObjectByIdAndChangeColor,
  showModel,
  hideModel,
  focusOnModel,
  findObjectByCode,
  getObjectCenter,
  // focusOnObject,
  // rotateCameraByQuaternion
  // calculateEuler,
  // rotateObjectByHorizontalAngle,
} from "@/assets/js/loaderModule";
import { modelFile, fontFile } from "@/utils/model.config";
import channelVue from "@/components/channel.vue";

const loadManage = new LoadingManager();
const gltfLoader = new GLTFLoader(loadManage);
// const fontLoader = new FontLoader();
const dracoLoader = new DRACOLoader();
let nextColor = null;
let acColor = null;
export default {
  name: "Manikin3D",
  components: {
    channelVue,
  },
  data() {
    return {
      scene: null,
      canvas: null,
      renderer: null,
      camera: null,
      controls: null,
      loadManage: null,
      fontPolarAngle: 0,
      fontAzimuthalAngle: 0,
      fontMesh: [],
      mouse: {},
      raycaster: new this.$Three.Raycaster(),
      pointsPrev: null,
      prop: {},
      stats: null,
      acAcupoint: true,
      propShow: false,
      model: [],
      selectModel: [null, null],
      totalSteps: 1,
      completedSteps: 0,
      fontLoadSuccess: null,
      loadingObj: null,
      acupointCode: null,
      modelObj: null,
      gltfScene: null,
      QXPoint: null, //处理奇穴多点数据
      // clickState: true, //控制点击
    };
  },
  created() {
    this.loadingObj = this.$loading.show({
      canCancel: true, // default false
      color: "#FFFFFF",
      loader: "dots",
      width: 34,
      height: 34,
      backgroundColor: "#000000",
      opacity: 0.7,
      zIndex: 999,
    });
    fontInit(fontFile, (font) => {
      this.fontLoadSuccess = font;
      if (this.totalSteps == this.completedSteps) {
        this.renderModel();
      }
      // this.loadingObj.hide();
      // this.renderModel();
    });
  },
  mounted() {
    Object.keys(modelFile).forEach((key) => {
      this.modeLoader(modelFile[key], key == "human" ? true : false);
    });
    this.loadingManager();
    this.initThree();
  },
  methods: {
    /**
     * 初始化
     */
    initThree() {
      //3D初始化场景承载dom
      // this.stats = new Stats();
      // this.stats.domElement.style.position = "absolute"; //绝对坐标
      // this.stats.domElement.style.left = "0px"; // (0,0)px,左上角
      // this.stats.domElement.style.top = "0px";
      // this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      // document.body.appendChild(this.stats.dom);
      this.scene = new this.$Three.Scene();
      this.scene.background = new this.$Three.Color("#fff");
      this.canvas = document.querySelector("#canvasMain");
      this.renderer = new this.$Three.WebGLRenderer({
        canvas: this.canvas,
        antialias: true,
        shadowMap: { enabled: true, type: this.$Three.PCFSoftShadowMap },
        gammaFactor: 2.2,
        gammaOutput: true,
        physicallyCorrectLights: true,
        toneMapping: this.$Three.ACESFilmicToneMapping,
        toneMappingExposure: 1.0,
        outputEncoding: this.$Three.sRGBEncoding,
      });
      this.camera = new this.$Three.PerspectiveCamera(
        50,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.position.z = 8;
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      // const helper = new this.$Three.CameraHelper(this.camera);
      // this.scene.add(helper);
      // this.initHelper();
      this.lightInit();
      this.animate();
    },
    /**
     * 模型加载
     * @param {模型地址} modeData
     * @param { 是否主模型 } modeMain
     */
    modeLoader(modeData, modeMain) {
      // draco压缩
      dracoLoader.setDecoderPath("/module/draco/");
      dracoLoader.setDecoderConfig({ type: "wasmBinary" });
      dracoLoader.preload();
      gltfLoader.setDRACOLoader(dracoLoader);
      // 加载模型
      gltfLoader.load(modeData, (gltf) => {
        if (modeMain) {
          this.gltfScene = gltf.scene;
          this.gltfScene.position.set(0, -3, 0);
          this.gltfScene.scale.set(3, 3, 3);
          this.gltfScene.traverse((o) => {
            if (o.name == "HG_Body001") {
              //给模型每部分上材质
              o.material = this.textureLoader(bodyImgPng);
            }
            if ((o.name == "Mesh003") | "Mesh003_1") {
              o.material = this.textureLoader(eyesImgPng);
            }
          });
          this.scene.add(this.gltfScene);
          // gui.add(this.gltfScene.rotateX, "x").min(0).max(5).step(0.01);
          // gui.add(this.gltfScene.rotateY, "y").min(0).max(360).step(0.01);
          // gui.add(this.gltfScene.rotateZ, "z").min(0).max(360).step(0.01);
          return;
        }
        gltf.scene.visible = false;
        this.model.push({
          model: gltf.scene,
          modeMain: modeMain,
        });
      });
    },
    processChild() {
      return (child) => {
        if (child.isMesh) {
          child.geometry = new BufferGeometry();
          child.geometry.computeVertexNormals();
          child.geometry.computeBoundingBox();
          child.geometry.computeBoundingSphere();
          child.material = new this.$Three.MeshStandardMaterial({
            color: child.material.color,
            roughness: child.material.roughness,
            metalness: child.material.metalness,
            map: child.material.map,
            normalMap: child.material.normalMap,
            normalScale: child.material.normalScale,
            envMap: child.material.envMap,
            envMapIntensity: child.material.envMapIntensity,
            side: this.$Three.DoubleSide,
          });
        }
      };
    },

    renderModel() {
      const _this = this;
      this.model.forEach((modelObj) => {
        modelObj.model.traverse((o) => {
          if (o.userData.name) {
            o.add(
              createFontMesh(o.userData.name, {
                font: _this.fontLoadSuccess,
                position: {
                  x: o.position.x,
                  y: o.position.y,
                  z: o.position.z,
                },
                color: "#4A4A4A",
                size: 1,
                height: 0.01,
                curveSegments: 1,
                bevelEnabled: false,
                bevelThickness: 0.02,
                bevelSize: 0,
                bevelSegments: 1,
              })
            );
          }
          if (
            (o.isMesh && o.userData.id) ||
            (o.material && o.material.userData.id)
          ) {
            o.material.emissive = o.material.color;
            o.material.emissiveMap = o.material.map;
          }
        });
        this.gltfScene.add(modelObj.model);
      });
      this.loadingObj.hide();
    },

    animate() {
      // 设置设备像素比
      this.renderer.setPixelRatio(1);
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
      fontRotate(this.camera.position);
      TWEEN.update();
      // this.stats.update();
      if (this.resizeRendererToDisplaySize()) {
        const canvas = this.renderer.domElement;
        this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        this.camera.updateProjectionMatrix();
      }
      this.controls.update();
    },
    /**
     * 窗口变更监听
     */
    resizeRendererToDisplaySize() {
      const canvas = this.renderer.domElement;
      var width = window.innerWidth;
      var height = window.innerHeight;
      var canvasPixelWidth = canvas.width / window.devicePixelRatio;
      var canvasPixelHeight = canvas.height / window.devicePixelRatio;

      const needResize =
        canvasPixelWidth !== width || canvasPixelHeight !== height;
      if (needResize) {
        this.renderer.setSize(width, height, false);
      }
      return needResize;
    },
    /**
     * 场景辅助网格
     */
    // initHelper() {
    //   let axes = new this.$Three.AxesHelper(30);
    //   axes.name = "AxesHelper";
    //   this.scene.add(axes);
    //   let gridHelper = new this.$Three.GridHelper(1000, 20, 0x2c2c2c, 0x888888);
    //   gridHelper.material.opacity = 0.1;
    //   gridHelper.material.transparent = true;
    //   gridHelper.name = "Grid";
    //   this.scene.add(gridHelper);
    // },
    /**
     * 光源初始化
     */
    lightInit() {
      const light = new this.$Three.AmbientLight(0x404040); // soft white light
      this.scene.add(light);
    },
    /**
     * 模型加载管理
     */
    loadingManager() {
      let _this = this;
      loadManage.onProgress = function (url, itemsLoaded, itemsTotal) {
        _this.totalSteps = itemsTotal;
        _this.completedSteps = itemsLoaded;
        if (itemsTotal == itemsLoaded && _this.fontLoadSuccess) {
          _this.renderModel();
        }
      };
    },
    /**
     * 纹理加载
     */
    textureLoader(textureData) {
      // 将图片作为纹理加载
      let explosionTexture = new this.$Three.TextureLoader().load(textureData);
      // 调整纹理图的方向
      explosionTexture.flipY = false;
      // 将纹理图生成基础网格材质(MeshBasicMaterial)
      const material = new this.$Three.MeshBasicMaterial({
        map: explosionTexture,
      });
      return material;
    },
    /* 鼠标移动事件 */
    // onMouseMove(event) {
    //   event.preventDefault();
    // fontRotate(this.camera.position);
    // },
    // // 鼠标抬起事件
    // onMouseUp(event) {
    //   event.preventDefault();
    // },
    getAcupointTarget(val) {
      this.acupointCode = val;
      getAcupointTarget({ code: val })
        .then((res) => {
          if (res.code == 200) {
            this.propShow = true;
            this.$set(this.prop, "content", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCode(val) {
      if (val && val.code) {
        this.prop.name = val.title;
        this.modelObj = findObjectByCode(this.model, val.code);
        if (this.modelObj.isGroup && !this.modelObj.userData.name) {
          nextColor = null;
          showModel(this.modelObj);
          this.selectModel[0] = this.modelObj;
        } else {
          // this.clickState = false;
          nextColor = nextColor
            ? nextColor
            : this.modelObj.isGroup
            ? this.modelObj.children[0].material.color
            : this.modelObj.material.color;
          this.selectModel[0].children.forEach((item) => {
            item.material ? (item.material.emissive = nextColor) : "";
          });
          acColor = new this.$Three.Color(
            generateContrastColor(
              this.modelObj.isGroup
                ? this.modelObj.children[0].material.color
                : this.modelObj.material.color
            )
          );
          if (this.modelObj.isGroup && this.modelObj.userData.name) {
            this.generateFunction();
            this.QXPoint = this.modelObj;
            for (let i = 0; i < this.modelObj.children.length; i++) {
              if (this.modelObj.children[i].material.userData.id) {
                findObjectByIdAndChangeColor(
                  this.modelObj.children[i],
                  this.modelObj.children[i].parent.children,
                  acColor
                );
              }
            }
          } else {
            this.generateFunction();
            this.modelObj.material.emissive = acColor;
          }
          this.animateCamera(
            this.camera.position,
            focusOnModel(this.camera, this.modelObj, 8),
            this.controls.target,
            getObjectCenter(this.modelObj)
          );
        }
        this.getAcupointTarget(val.code);
      } else {
        this.propShow = false;
        this.controls.reset();
        if (!this.modelObj.isGroup) {
          if (this.modelObj.material.userData.id) {
            findObjectByIdAndChangeColor(
              this.modelObj,
              this.modelObj.parent.children,
              this.modelObj.material.color
            );
          } else {
            this.modelObj.material.emissive = nextColor;
          }
        } else {
          for (let i = 0; i < this.modelObj.children.length; i++) {
            if (this.modelObj.children[i].material.userData.id) {
              findObjectByIdAndChangeColor(
                this.modelObj.children[i],
                this.modelObj.children[i].parent.children,
                this.modelObj.children[i].material.color
              );
            }
          }
        }
        hideModel(this.selectModel[0]);
        // rotateObjectByHorizontalAngle(
        //   this.gltfScene,
        //   -this.controls.getAzimuthalAngle() * 200
        // );
      }
    },
    generateFunction() {
      if (this.QXPoint) {
        for (let i = 0; i < this.QXPoint.children.length; i++) {
          if (this.QXPoint.children[i].material.userData.id) {
            findObjectByIdAndChangeColor(
              this.QXPoint.children[i],
              this.QXPoint.children[i].parent.children,
              nextColor
            );
          }
        }
        this.QXPoint = null;
      }
    },

    // current1 相机当前的位置
    // target1 相机的目标位置
    // current2 当前的controls的target
    // target2 新的controls的target
    animateCamera(current1, target1, current2, target2) {
      let _this = this;
      var tween = new TWEEN.Tween({
        x1: current1.x, // 相机当前位置x
        y1: current1.y, // 相机当前位置y
        z1: current1.z, // 相机当前位置z
        x2: current2.x, // 控制当前的中心点x
        y2: current2.y, // 控制当前的中心点y
        z2: current2.z, // 控制当前的中心点z
      });
      tween.to(
        {
          x1: target1.x, // 新的相机位置x
          y1: target1.y, // 新的相机位置y
          z1: target1.z, // 新的相机位置z
          x2: target2.x, // 新的控制中心点位置x
          y2: target2.y, // 新的控制中心点位置x
          z2: target2.z, // 新的控制中心点位置x
        },
        1000
      );
      tween.onUpdate(function () {
        _this.camera.position.x = this._object.x1;
        _this.camera.position.y = this._object.y1;
        _this.camera.position.z = this._object.z1;
        _this.controls.target.x = this._object.x2;
        _this.controls.target.y = this._object.y2;
        _this.controls.target.z = this._object.z2;
        _this.controls.update();
      });
      tween.onComplete(function () {
        // console.log("onComplete");
        _this.controls.enabled = true;
        fontRotate(_this.camera.position);
        // _this.clickState = true;
      });
      tween.easing(TWEEN.Easing.Cubic.InOut);
      tween.start();
    },
  },
};
</script>

<style lang="scss" scoped>
#Manikin3D {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .propBox {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 38vh;
    border-top: 1px solid #e0e0e0;
    .tit {
      width: 100%;
      height: 8vh;
      text-align: center;
      line-height: 8vh;
      font-size: 20px;
      font-weight: 600;
      color: #b2b2b2;
      // background: #000000c7;
      background: linear-gradient(
        to left,
        #000000d6 0%,
        #000000c7 50%,
        #000000d6 100%
      );
      border-bottom: 1px solid #e0e0e0;
    }
    .main {
      padding-top: 1vh;
      padding-bottom: 1vh;
      font-size: 14px;
      font-weight: 600;
      color: #d1d1d1;
      background: #000000b8;
      height: 15vh;
      overflow-y: scroll;
      p {
        margin: 0;
        padding: 5px 10px;
        span {
          font-weight: 500;
          color: #04b4fa;
        }
      }
    }
  }
  .propShow {
    min-height: 8vh;
    height: auto;
    animation-name: scaleShow;
    animation-duration: 0.1s;
  }
  .propHide {
    height: 8vh;
    animation-name: scaleHide;
    animation-duration: 0.5s;
  }
  .radial-progress-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
@keyframes scaleShow {
  from {
    height: 0vh;
  }
  to {
    height: 8vh;
  }
}
@keyframes scaleHide {
  from {
    height: 8vh;
  }
  to {
    height: 8vh;
  }
}
</style>
